.react-grid-item > .react-resizable-handle::after {
   content: "";
   position: absolute;
   right: 3px;
   bottom: 3px;
   width: 5px;
   height: 5px;
   border-right: 2px solid rgba(255, 255, 255, 0.9);
  border-bottom: 2px solid rgba(255, 255, 255, 0.9);  /*Update rgba values for color and transparency. */
}

.react-grid-item{
  background-color:#282c34;
  border:1px solid rgb(0, 0, 0);
}
.react-grid-layout {
  border: 1px solid #ee043f;
}

/* 

react-multi-select-component styles 

*/
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}


/* hide Tag for selected value*/

.react-multi-select-component .tag-item .tag {
  display: none;
}

.react-multi-select-component .tag-list {
  list-style-type: none;
}

/* hide box for Parent */
.react-multi-select-component .inActive .checkbox-item {
  display: none;
}

.react-multi-select-component .inActive label {
  pointer-events: none;
  color: #FFFFFF;
}

.react-multi-select-component label {
  color: #FFFFFF;
}


.react-multi-select-component .toggle {
  font: normal normal normal 18px/1 "Material Icons";
  color: #FFFFFF;
  white-space: pre;
  margin-right: 4px;
}

.react-multi-select-component .toggle.collapsed::after {
  cursor: pointer;
  content: "\E5CF";
  vertical-align: middle;
}

.react-multi-select-component .toggle.expanded::after {
  cursor: pointer;
  content: "\E5CE";
  vertical-align: middle;
}

/* checkbox styles */
.react-multi-select-component .checkbox-item {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: middle;
}

.react-multi-select-component .checkbox-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  transition: all 0.3s ease-in-out;
}

.react-multi-select-component .checkbox-item:checked:before {
  height: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top-style: none;
  border-right-style: none;
  border-color: #2196f3;
}

.react-multi-select-component .infinite-scroll-component {
  width: 500px;
  
}
