.App {
  text-align: center;
  /* background-color:#0e3040; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*Candleticks format */
.bars.up-day path {
  stroke: #03a678;
}
.bars.down-day path {
  stroke: #c0392b;
}
.bars.up-day rect {
  fill: #03a678;
}
.bars.down-day rect{
  fill: #c0392b;
}

.flex-container {
  background: #0e3040;
  color: #67809f;
  /* max-width: 80vw; */
  /* height: 100%; */
}

.priceboard-modal {
  max-width:80vw;
  max-height:120vh
}



/* .table.table-bordered {
  background-color: #282c34;
} */

/* .dashboard-content {
  background-color:#0e3040;
} */

